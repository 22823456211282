import React from 'react';
import { Container, Typography, Grid, Box, Paper, Button, Accordion, AccordionSummary, AccordionDetails, Avatar, Card, CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Repairs from '../images/repair-service.jpg';
import WebDesign from '../images/web-design.jpg';
import Consulting from '../images/consulting-service.jpeg';
import montacgepc from '../images/montagepc.png';
import reconditionee from '../images/reconditionnee.png';

// Liste des services proposés
const services = [
  {
    title: 'Réparations Informatiques',
    description: 'Nous offrons des services complets de réparation pour tous vos appareils, y compris ordinateurs, tablettes et téléphones. Nos techniciens qualifiés diagnostiquent rapidement les problèmes et effectuent des réparations efficaces. Tous les travaux sont garantis pour votre tranquillité d’esprit.',
    time: '1-3 jours',
    image: Repairs,
    packages: [
      { name: 'DIAGNOSTIC', price: '25€', description: 'Analyse rapide de votre appareil' },
      { name: 'REPARATION DU PC', price: 'à partir de 100€', description: 'Réparations matérielles et logicielles' },
      { name: 'REPARATION DE TELEPHONE', price: 'à partir de 80€', description: 'Changement d’écran et réparations diverses' },
    ]
  },
  {
    title: 'Création de Sites Web',
    description: 'Notre équipe de développeurs crée des sites web personnalisés qui reflètent votre marque. Que vous ayez besoin d’un site vitrine ou d’un site e-commerce, nous avons les compétences pour réaliser votre projet sur mesure. Nos sites sont optimisés pour le référencement et adaptés aux mobiles.',
    time: '2-4 semaines',
    image: WebDesign,
    packages: [
      { name: 'SITE VITRINE', price: 'à partir de 500€', description: 'Un site simple pour présenter votre activité' },
      { name: 'SITE E-COMMERCE', price: 'à partir de 1500€', description: 'Un site complet pour vendre en ligne' },
    ]
  },
  {
    title: 'Conseils Informatiques',
    description: 'Nous offrons des conseils stratégiques pour optimiser vos outils informatiques et assurer la sécurité de vos données. Que ce soit pour la sauvegarde, la sécurité ou les solutions logicielles, nous sommes là pour vous aider. Nous proposons également des formations pour vous rendre autonome.',
    time: '1 semaine',
    image: Consulting,
    packages: [
      { name: 'AUDIT DE SECURITE', price: '100€', description: 'Évaluation complète de votre sécurité informatique' },
      { name: 'FORMATION PERSONNALISEE', price: '300€', description: 'Formation sur mesure adaptée à vos besoins' },
    ]
  },
  {
    title: 'Montage PC Gamer',
    description: 'Nous construisons des PC Gamer sur mesure en fonction de vos besoins et de votre budget. Que vous soyez un joueur occasionnel ou un hardcore gamer, nous vous fournissons un PC optimisé pour la performance.',
    time: '1-2 semaines',
    image: montacgepc, // Remplacez par le chemin de votre image
    packages: [
      { name: 'PC GAMER BASIQUE', price: 'à partir de 800€', description: 'Configuration adaptée pour les jeux en 1080p' },
      { name: 'PC GAMER HAUTE PERFORMANCE', price: 'à partir de 1500€', description: 'Configuration pour le gaming en 4K et VR' },
    ]
  },
  {
    title: 'Vente d\'appareils reconditionnés',
    description: 'Nous proposons une gamme d\'appareils reconditionnés de haute qualité, y compris des ordinateurs portables, des tablettes et des téléphones. Tous nos appareils sont testés, réinitialisés et garantis.',
    time: 'Disponible immédiatement',
    image: reconditionee, // Remplacez par le chemin de votre image
    packages: [
      { name: 'ORDINATEURS PORTABLES', price: 'à partir de 200€', description: 'Ordinateurs portables reconditionnés de différentes marques' },
      { name: 'SMARTPHONES', price: 'à partir de 100€', description: 'Smartphones reconditionnés avec garantie de 6 mois' },
    ]
  },
];


const TestimonialCard = ({ text, color }) => (
  <Card 
    sx={{ 
      width: 288, // Equivalent to w-72
      backgroundColor: '#374151', // bg-gray-700
      boxShadow: 3, 
      p: 2, 
      borderRadius: 2, // rounded-md
      transition: 'transform 0.3s', 
      '&:hover': { transform: 'translateY(-10px)' }, // hover:-translate-y-2
      margin: '0 10px',  // Add margin to avoid touching the edge of the slider
      height: '100%' // Make sure it takes full height
    }}
  >
    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Avatar sx={{ bgcolor: '#4B5563', width: 40, height: 40 }}> 
        <CheckCircleIcon sx={{ color: '#D1D5DB' }} />
      </Avatar>
      <Typography variant="body2" align="center" sx={{ color: color }}>
        {text}
      </Typography>
    </CardContent>
  </Card>
);

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 2, // Show 2 slides at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // On small screens
        settings: {
          slidesToShow: 1, // Show 1 slide at a time
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <Box sx={{ margin: '40px 0', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ 
        padding: '20px', 
        borderRadius: 2, 
        width: '100%', 
        maxWidth: '800px', 
        textAlign: 'center' 
      }}>
        <Typography variant="h5" component="h4" gutterBottom color="#000">
          Ce que nos clients disent
        </Typography>
        <Slider {...settings} style={{ height: '100%' }}>
          <TestimonialCard 
            text="“Micropple a transformé notre présence en ligne avec un site incroyable et un service à la clientèle exceptionnel!”"
            color="#fff"
          />
          <TestimonialCard 
            text="“Un service rapide et efficace! Je recommande fortement Micropple pour toute réparation.”"
            color="#fff"
          />
          <TestimonialCard 
            text="“Un excellent service de bout en bout, je ne peux que les recommander.”"
            color="#fff"
          />
          <TestimonialCard 
            text="“Des professionnels compétents et toujours prêts à aider!”"
            color="#fff"
          />
        </Slider>
      </Box>
    </Box>
  );
};

const ServicePackages = ({ packages }) => (
  <Box sx={{ marginTop: '20px' }}>
    <Typography variant="h6" component="h4" gutterBottom>
      Forfaits Disponibles
    </Typography>
    {packages.map((pkg, index) => (
      <Paper key={index} sx={{ padding: '10px', marginBottom: '10px', backgroundColor: '#1e90ff', color: '#E0E0E0', fontFamily: 'Ubuntu' }}>
        <Typography variant="body1" fontWeight="bold">{pkg.name}</Typography>
        <Typography variant="body2">{pkg.description}</Typography>
      </Paper>
    ))}
  </Box>
);

const FAQSection = () => {
  const faqItems = [
    {
      question: 'Quels types de réparations offrez-vous ?',
      answer: 'Nous offrons une large gamme de réparations, y compris pour les ordinateurs, les tablettes, et les téléphones. Que ce soit pour un problème matériel ou logiciel, nos techniciens sont prêts à vous aider.'
    },
    {
      question: 'Combien de temps cela prend-il pour réparer un appareil ?',
      answer: 'Le temps de réparation dépend du type de service. En général, les réparations peuvent prendre entre 1 à 3 jours.'
    },
    {
      question: 'Proposez-vous des garanties sur vos services ?',
      answer: 'Oui, tous nos services de réparation sont garantis. Si le problème persiste après la réparation, nous nous engageons à le corriger sans frais supplémentaires.'
    },
    {
      question: 'Comment puis-je demander un devis ?',
      answer: 'Vous pouvez demander un devis en nous contactant directement via notre page de contact ou en remplissant le formulaire de devis sur notre site.'
    },
    {
      question: 'Acceptez-vous les paiements en plusieurs fois ?',
      answer: 'Non, malheureusement nous n’acceptons pas les paiements en plusieurs fois pour nos services.'
    },
  ];

  return (
    <Box sx={{ margin: '40px 0' }}>
      <Typography variant="h5" component="h4" gutterBottom align="center">
        Questions Fréquemment Posées
      </Typography>
      {faqItems.map((item, index) => (
        <Accordion key={index} sx={{ marginBottom: '10px', backgroundColor: '#1e90ff', color: '#E0E0E0' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-content-${index}`} id={`faq-header-${index}`}>
            <Typography variant="body1">{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2">{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

const ServicesPage = () => (
  <Container sx={{ padding: '100px 0', color: '#333' }}>
    <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ fontFamily: 'Bebas Neue, Arial, sans-serif', padding: '50px' }}>
      Nos Services Proposés
    </Typography>

    <Grid container spacing={4}>
      {services.map((service, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Paper sx={{ padding: '20px', borderRadius: '16px', backgroundColor: '#1466b8', color: '#E0E0E0' }}>
            <Box
              component="img"
              src={service.image}
              alt={service.title}
              sx={{ width: '100%', height: '150px', objectFit: 'cover', borderRadius: '8px', marginBottom: '16px' }}
            />
            <Typography variant="h6" component="h3" gutterBottom>
              {service.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {service.description}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Temps de réalisation:</strong> {service.time}
            </Typography>
            <ServicePackages packages={service.packages} />
          </Paper>
        </Grid>
      ))}
    </Grid>

    <Testimonials />

    <FAQSection />

    <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
      <Typography variant="h5" component="h4" gutterBottom>
        Prêt à démarrer ?
      </Typography>
      <Button variant="contained" color="primary" href="/contact-page" sx={{ marginTop: '20px' }}>
        Contactez-Nous pour un Devis
      </Button>
    </Box>
  </Container>
);

export default ServicesPage;
