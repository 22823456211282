import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import laptopImage from '../images/laptop.jpg';
import phoneImage from '../images/phone.jpg';
import webImage from '../images/web.jpg';

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  
  };

  const imageStyles = {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '8px',
    transition: 'transform 0.5s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)'
    }
  };

  const cardStyles = {
    width: '100%',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    padding: 2,
    border: '2px solid #c3c6ce',
    transition: '0.5s ease-out',
    overflow: 'visible',
    '&:hover': {
      borderColor: '#008bf8',
      boxShadow: '0 4px 18px 0 rgba(0, 0, 0, 0.25)',
      transform: 'scale(1.03)'
    }
  };

  return (
    <Box>
      <br />
      <br />
      <br />
    <Box
      id="home"
      sx={{
        padding: '100px 0',
        position: 'relative',
        overflow: 'hidden',
        color: '#fff',
      }}
    >
   
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      >
         
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 1600 900" preserveAspectRatio="xMidYMax slice">
          <defs>
          <linearGradient id="bg">
            <stop offset="0%" style={{ stopColor: '#d4d4d4' }}></stop>
            <stop offset="50%" style={{ stopColor: 'rgba(26, 140, 205, 0.8)' }}></stop>
            <stop offset="100%" style={{ stopColor: '#152b85' }}></stop>
          </linearGradient>
            <path id="wave" fill="url(#bg)" d="M-363.852,502.589c0,0,236.988-41.997,505.475,0
              s371.981,38.998,575.971,0s293.985-39.278,505.474,5.859s493.475,48.368,716.963-4.995v560.106H-363.852V502.589z" />
          </defs>
          <g>
            <use xlinkHref='#wave' opacity=".3">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="10s"
                calcMode="spline"
                values="270 230; -334 180; 270 230"
                keyTimes="0; .5; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite" />
            </use>
            <use xlinkHref='#wave' opacity=".6">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="8s"
                calcMode="spline"
                values="-270 230;243 220;-270 230"
                keyTimes="0; .6; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite" />
            </use>
            <use xlinkHref='#wave' opacity=".9">
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                dur="6s"
                calcMode="spline"
                values="0 230;-140 200;0 230"
                keyTimes="0; .4; 1"
                keySplines="0.42, 0, 0.58, 1.0;0.42, 0, 0.58, 1.0"
                repeatCount="indefinite" />
            </use>
          </g>
        </svg>
      </Box>

      <Container>
        <Typography 
          variant="h4" 
          component="h2" 
          gutterBottom 
          align="center" 
          sx={{ 
            fontFamily: 'Bebas Neue, sans-serif', 
            color: '#000',
            marginBottom: 4,
            position: 'relative',
            '&::before': {
              content: '""',
              position: 'absolute',
              width: '50px',
              height: '3px',
              backgroundColor: '#333333',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)'
            }
          }}
        >
          Qui sommes-nous ?
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card sx={cardStyles}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h4" component="p" sx={{ fontWeight: 'bold', textAlign: "left" , fontFamily: 'Ubuntu' }}>
                  Micropple
                </Typography>
                <br />
                <Typography variant="body2" color="textSecondary" sx={{fontFamily: 'Ubuntu', fontSize:'17px'}}>
                  Micropple est une entreprise spécialisée dans les solutions informatiques complètes.
                  Nous offrons des services de réparation informatique, de téléphonie, de création de sites web et de conseils informatiques.
                  <br />
                  Que vous soyez un particulier ou une entreprise, nous avons les compétences et l’expertise pour répondre à vos besoins technologiques.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Carrousel d'images */}
            <Box sx={{ height: '100%', position: 'relative' }}>
              <Slider {...settings} style={{ height: '100%' }}>
                <div style={{ height: '100%' }}>
                  <img src={laptopImage} alt="Laptop" style={imageStyles} />
                </div>
                <div style={{ height: '100%' }}>
                  <img src={phoneImage} alt="Phone" style={imageStyles} />
                </div>
                <div style={{ height: '100%' }}>
                  <img src={webImage} alt="Web Design" style={imageStyles} />
                </div>
              </Slider>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
    </Box>
   
  );
};

export default Home;
