import React from 'react';
import { Container, Typography, Grid, Box, Paper } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import ContactForm from '../components/Contact';
import companyLogo from '../images/logo.png';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const customIcon = new L.Icon({
  iconUrl: require('../images/location-pin.png'),
  iconSize: [30, 30],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const ContactPage = () => {
  const position = [48.8273318, 2.2645653];

  return (
    <Box>
      <br />  
      <br />
      <br />
    <Container id="contact" sx={{ padding: '80px 0', color: '#333' }}>
      <Box
        sx={{
          textAlign: 'center',
          paddingBottom: '50px',
          backgroundColor: '#1E3A8A',
          color: '#ffffff',
          borderRadius: '8px',
          padding: '40px 20px',
        }}
      >
        <Typography variant="h3" component="h1" sx={{ fontFamily: 'Playfair Display, Arial, sans-serif', marginBottom: '20px' }}>
          Contactez-Nous
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'Roboto, Arial, sans-serif', fontSize: '18px' }}>
          Nous sommes là pour répondre à toutes vos questions et vous aider avec vos besoins informatiques.
        </Typography>
      </Box>

      <Grid container spacing={4} sx={{ marginTop: '40px' }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" component="h3" gutterBottom>
            Informations de Contact
          </Typography>
          <Paper sx={{ padding: '20px', backgroundColor: '#ffffff', color: '#333', borderRadius: '8px' }}>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <PlaceIcon sx={{ color: '#1E3A8A', marginRight: '10px' }} />
              <Typography variant="body1">
                68 boulevard Galleni, Issy-les-Moulineaux , 92130
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="10px">
              <PhoneIcon sx={{ color: '#1E3A8A', marginRight: '10px' }} />
              <Typography variant="body1">
                +33 7 60 66 07 53
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ color: '#1E3A8A', marginRight: '10px' }} />
              <Typography variant="body1">
                contact.micropple@gmail.com
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{ height: '400px', borderRadius: '8px' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position} icon={customIcon}>
              <Popup>
                <Box sx={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    src={companyLogo}
                    alt="Micropple Logo"
                    sx={{
                      width: '100%',
                      maxHeight: '60px',
                      objectFit: 'contain',
                      mb: 2,
                    }}
                  />
                  <Typography variant="body1" gutterBottom>
                    Votre Partenaire Informatique de Confiance
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Adresse:</strong> 68 boulevard Galleni, Issy-les-Moulineaux , 92130, France
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Téléphone:</strong> +33 7 60 66 07 53
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <strong>Email:</strong> contact@micropple.fr
                  </Typography>
                </Box>
              </Popup>
            </Marker>
          </MapContainer>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <ContactForm />
      </Box>
    </Container>
  </Box>
  );
};

export default ContactPage;
