import React from 'react';

import Home from '../components/Home';
import Services from '../components/Services';
import Contact from '../components/Contact';


const HomePage = () => {
  return (
    <>
      <Home />
      <Services />
      <Contact />
    </>
  );
};

export default HomePage;
