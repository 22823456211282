import React from 'react';
import { Container, Typography, Grid, Paper, Box } from '@mui/material';
import ComputerIcon from '@mui/icons-material/Computer';
import WebIcon from '@mui/icons-material/Web';
import SecurityIcon from '@mui/icons-material/Security';
import BuildIcon from '@mui/icons-material/Build';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';

const services = [
  {
    icon: <ComputerIcon fontSize="large" />,
    title: 'Réparations Informatiques et Téléphones',
    description: 'Remise en état de votre matériel informatique (PC, Mac, tablettes) et de vos téléphones (Android, iPhone). Diagnostic rapide et réparations efficaces.'
  },
  {
    icon: <WebIcon fontSize="large" />,
    title: 'Création de Sites Web',
    description: 'Conception et développement de sites web personnalisés, adaptés à vos besoins et optimisés pour les moteurs de recherche. De la vitrine professionnelle au site e-commerce, nous réalisons votre projet sur mesure.'
  },
  {
    icon: <SecurityIcon fontSize="large" />,
    title: 'Conseils Informatiques',
    description: 'Accompagnement et conseils pour optimiser vos outils informatiques. Sécurité, sauvegarde de données, solutions logicielles, nous vous aidons à prendre les meilleures décisions.'
  },
  {
    icon: <BuildIcon fontSize="large" />,
    title: 'Montage PC Gamer',
    description: 'Nous construisons des PC Gamer sur mesure en fonction de vos besoins et de votre budget. Que vous soyez un joueur occasionnel ou un hardcore gamer, nous vous fournissons un PC optimisé pour la performance.'
  },
  {
    icon: <PhoneIphoneIcon fontSize="large" />,
    title: 'Vente d\'appareils reconditionnés',
    description: 'Nous proposons une gamme d\'appareils reconditionnés de haute qualité, y compris des ordinateurs portables, des tablettes et des téléphones. Tous nos appareils sont testés, réinitialisés et garantis.'
  }
];

const ServiceCard = ({ icon, title, description }) => (
  <Paper
    elevation={3}
    sx={{
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '300px',
      height: '400px',
      borderRadius: '24px',
      lineHeight: 1.6,
      padding: '36px',
      background: '#6eb1f0',
      color: '#333333',
      transition: 'all 0.48s cubic-bezier(0.23, 1, 0.32, 1)',
      '&:hover': {
        transform: 'translate(0px, -16px)',
      },
      '&:hover::before': {
        rotate: '-8deg',
        top: 0,
        width: '100%',
        height: '100%',
      },
      '&:hover::after': {
        rotate: '8deg',
        top: 0,
        width: '100%',
        height: '100%',
      },
      '&::before': {
        position: 'absolute',
        content: '""',
        top: '-4%',
        left: '50%',
        width: '90%',
        height: '90%',
        transform: 'translate(-50%)',
        background: '#5b90c2',
        zIndex: -1,
        transformOrigin: 'bottom',
        borderRadius: 'inherit',
        transition: 'all 0.48s cubic-bezier(0.23, 1, 0.32, 1)',
      },
      '&::after': {
        position: 'absolute',
        content: '""',
        top: '-8%',
        left: '50%',
        width: '80%',
        height: '80%',
        transform: 'translate(-50%)',
        background: '#335a80',
        zIndex: -2,
        transformOrigin: 'bottom',
        borderRadius: 'inherit',
        transition: 'all 0.48s cubic-bezier(0.23, 1, 0.32, 1)',
      },
    }}
  >
    <Box sx={{ mb: 2 }}>
      {icon}
    </Box>
    <Typography variant="h6" component="h3" gutterBottom>
      {title}
    </Typography>
    <Typography className="para" sx={{ zIndex: 1, opacity: 1, fontSize: '16px', transition: 'all 0.48s cubic-bezier(0.23, 1, 0.32, 1)' }}>
      {description}
    </Typography>
  </Paper>
);

const Services = () => (
  <Box sx={{ background: '#F3F4F6' }}>
    <Container id="services" sx={{ padding: '100px 0' }}>
      <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ fontFamily: 'Bebas Neue, Arial, sans-serif' ,padding: '50px' }}>   
        Nos Services
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item key={index}>
            <ServiceCard icon={service.icon} title={service.title} description={service.description} />
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
);

export default Services;
