import React from 'react';
import { Link as Link2 } from 'react-router-dom';
import { Container, Typography, Grid, Box, Link } from '@mui/material';
import { FaWhatsappSquare, FaInstagram, FaTwitterSquare } from 'react-icons/fa';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#1F2937', py: 8 }} id="footer">
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: '#fff' }}>
              MICROPPLE
            </Typography>
            <Box sx={{ py: 4, color: '#fff' }}>
              <Typography variant="body1">
                Adresse: 68 boulevard Galleni, 92130 Issy-les-Moulineaux

                <br />
                Téléphone: 07 82 50 42 65
                <br />
                Email: contact.micropple@gmail.com
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: { xs: '50%', md: '50%' }, my: 6 }}>
              <Link href="https://wa.link/zr968a" color={'#fff'} target="_blank" rel="noopener noreferrer">
                <FaWhatsappSquare size={30} />
              </Link>
              <Link href="https://www.instagram.com" color={'#fff'} target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} />
              </Link>
              <Link href="https://twitter.com" color={'#fff'} target="_blank" rel="noopener noreferrer">
                <FaTwitterSquare size={30} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={4}>
              <Grid item xs={6} md={3}>
                <Typography variant="h6" component="h6" sx={{ fontWeight: 'medium', color: '#fff' }}>
                  Services Proposés
                </Typography>
                <ul>
                  <li>
                    <Link2 to="/services-page" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        Réparation ordinateur
                      </Typography>
                    </Link2>
                  </li>
                  <li>
                    <Link2 to="/services-page" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        Réparation téléphone
                      </Typography>
                    </Link2>
                  </li>
                  <li>
                    <Link2 to="/services-page" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        Création de site web
                      </Typography>
                    </Link2>
                  </li>
                  <li>
                    <Link2 to="/services-page" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        Conseils informatiques
                      </Typography>
                    </Link2>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="h6" component="h6" sx={{ fontWeight: 'medium', color: '#fff' }}>
                  Notre entreprise
                </Typography>
                <ul>
                  <li>
                    <Link2 to="/enterprise" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        À propos de Nous
                      </Typography>
                    </Link2>
                  </li>
                  <li>
                    <Link2 to="/enterprise" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        Notre équipe Développement
                      </Typography>
                    </Link2>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="h6" component="h6" sx={{ fontWeight: 'medium', color: '#fff' }}>
                  Legal
                </Typography>
                <ul>
                  <li>
                    <Link2 to="/legal" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        RGPD
                      </Typography>
                    </Link2>
                  </li>
                  <li>
                    <Link2 to="/legal" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        Politique de Confidentialité
                      </Typography>
                    </Link2>
                  </li>
                  <li>
                    <Link2 to="/legal" style={{ textDecoration: 'none', color: '#E0E0E0' }}>
                      <Typography variant="body2" sx={{ py: 1 }}>
                        Termes & Conditions
                      </Typography>
                    </Link2>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
