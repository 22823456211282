import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import logo from '../images/logo.png'; // Assurez-vous d'avoir un logo à cette adresse

const Header = () => (
  <AppBar position="fixed" sx={{ background: '#152b85', boxShadow: 'none', fontFamily:'Oswald, Arial, sans-serif' }}>
    <Toolbar>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <img src={logo} alt="Micropple Logo" style={{ width: '100px', marginRight: '15px' }} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontFamily: 'Roboto', color: '#FFFFFF' }}>
          Votre partenaire informatique de confiance
        </Typography>
      </Box>
      <nav>
        <Link
          href="/"
          color="inherit"
          underline='none'
          sx={{
            margin: '0 20px',
            display: 'inline-block',
            fontSize: '1.2rem', // Agrandir la taille de la police
            position: 'relative',
            '&:after': {
              content: '""',
              display: 'block',
              width: '0',
              height: '2px',
              background: 'white',
              transition: 'width 0.4s',
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
            '&:hover:after': {
              width: '100%',
            },
            '&:focus:after': {
              width: '100%',
            },
          }}
        >
          Accueil
        </Link>
        <Link
          href="/services-page"
          color="inherit"
          underline='none'
          sx={{
            margin: '0 20px',
            display: 'inline-block',
            fontSize: '1.2rem', // Agrandir la taille de la police
            position: 'relative',
            '&:after': {
              content: '""',
              display: 'block',
              width: '0',
              height: '2px',
              background: 'white',
              transition: 'width 0.4s',
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
            '&:hover:after': {
              width: '100%',
            },
            '&:focus:after': {
              width: '100%',
            },
          }}
        >
          Services
        </Link>
        <Link
          href="/contact-page"
          color="inherit"
          underline='none'
          sx={{
            margin: '0 20px',
            display: 'inline-block',
            fontSize: '1.2rem', // Agrandir la taille de la police
            position: 'relative',
            '&:after': {
              content: '""',
              display: 'block',
              width: '0',
              height: '2px',
              background: 'white',
              transition: 'width 0.4s',
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
            '&:hover:after': {
              width: '100%',
            },
            '&:focus:after': {
              width: '100%',
            },
          }}
        >
          Contact
        </Link>
      </nav>
    </Toolbar>
  </AppBar>
);

export default Header;
