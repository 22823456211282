import React from 'react';
import { Container, Typography, Box, Link, Divider, List, ListItem } from '@mui/material';

const LegalPage = () => {
  return (
    <Container sx={{ py: 8 }}>
      {/* Section Legal - Header with Navigation */}
      <br />
      <br />
      <br />
      <Box sx={{ mb: 6 }}>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 4 }}>
          Legal
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 4 }}>
          Bienvenue dans la section relative aux droits de notre site. Ici, vous trouverez des informations cruciales concernant la RGPD, notre Politique de Confidentialité, ainsi que nos Termes & Conditions. Ces documents sont essentiels pour comprendre vos droits et responsabilités lors de l'utilisation de nos services.
        </Typography>

        {/* Navigation Links */}
        <List component="nav" sx={{ display: 'flex', gap: 3, mb: 4 }}>
          <ListItem component="div">
            <Link href="#rgpd" underline="hover" sx={{ fontWeight: 'bold', color: '#1E3A8A' }}>
              RGPD
            </Link>
          </ListItem>
          <ListItem component="div">
            <Link href="#confidentiality" underline="hover" sx={{ fontWeight: 'bold', color: '#1E3A8A' }}>
              Politique de Confidentialité
            </Link>
          </ListItem>
          <ListItem component="div">
            <Link href="#terms" underline="hover" sx={{ fontWeight: 'bold', color: '#1E3A8A' }}>
              Termes & Conditions
            </Link>
          </ListItem>
        </List>
      </Box>

      {/* Section RGPD */}
<Box id="rgpd" sx={{ mb: 8 }}>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mb: 2 }}
  >
    Définitions
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Client : tout professionnel ou personne physique capable au sens des
    articles 1123 et suivants du Code civil, ou personne morale, qui visite le
    Site objet des présentes conditions générales.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Prestations et Services : https://micropple.fr met à disposition des
    Clients :
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Contenu : Ensemble des éléments constituants l’information présente sur le
    Site, notamment textes – images – vidéos.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Informations clients : Ci après dénommé « Information (s) » qui
    correspondent à l’ensemble des données personnelles susceptibles d’être
    détenues par https://micropple.fr pour la gestion de votre compte, de la
    gestion de la relation client et à des fins d’analyses et de statistiques.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Utilisateur : Internaute se connectant, utilisant le site susnommé.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Informations personnelles : « Les informations qui permettent, sous quelque
    forme que ce soit, directement ou non, l'identification des personnes
    physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6
    janvier 1978).
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Les termes « données à caractère personnel », « personne concernée », «
    sous-traitant » et « données sensibles » ont le sens défini par le
    Règlement Général sur la Protection des Données (RGPD : n° 2016-679).
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    1. Présentation du site internet.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
    confiance dans l'économie numérique, il est précisé aux utilisateurs du site
    internet https://micropple.fr l'identité des différents intervenants dans le
    cadre de sa réalisation et de son suivi:
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Propriétaire : Sébastien Torres – 68 boulevard Galleni 92130
    Issy-Les-Moulineaux
    <br />
    Responsable publication : John Ripper – contact.micropple@gmail.com <br />
    Le responsable publication est une personne physique ou une personne morale.
    <br />
    Webmaster : John Ripper – contact.micropple@gmail.com <br />
    Hébergeur : ovh – 2 rue Kellermann 59100 Roubaix 1007 <br />
    Délégué à la protection des données : John Ripper –
    contact.micropple@gmail.com
    <br />
    Les mentions légales sont issues du modèle proposé par le générateur de
    mentions légales RGPD d'Orson.io
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    2. Conditions générales d’utilisation du site et des services proposés.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Le Site constitue une œuvre de l’esprit protégée par les dispositions du
    Code de la Propriété Intellectuelle et des Réglementations Internationales
    applicables. Le Client ne peut en aucune manière réutiliser, céder ou
    exploiter pour son propre compte tout ou partie des éléments ou travaux du
    Site.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    L’utilisation du site https://micropple.fr implique l’acceptation pleine et
    entière des conditions générales d’utilisation ci-après décrites. Ces
    conditions d’utilisation sont susceptibles d’être modifiées ou complétées à
    tout moment, les utilisateurs du site https://micropple.fr sont donc invités
    à les consulter de manière régulière.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Ce site internet est normalement accessible à tout moment aux utilisateurs.
    Une interruption pour raison de maintenance technique peut être toutefois
    décidée par https://micropple.fr, qui s’efforcera alors de communiquer
    préalablement aux utilisateurs les dates et heures de l’intervention. Le
    site web https://micropple.fr est mis à jour régulièrement par
    https://micropple.fr responsable. De la même façon, les mentions légales
    peuvent être modifiées à tout moment : elles s’imposent néanmoins à
    l’utilisateur qui est invité à s’y référer le plus souvent possible afin
    d’en prendre connaissance.
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    3. Description des services fournis.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Le site internet https://micropple.fr a pour objet de fournir une
    information concernant l’ensemble des activités de la société.
    https://micropple.fr s’efforce de fournir sur le site https://micropple.fr
    des informations aussi précises que possible. Toutefois, il ne pourra être
    tenu responsable des oublis, des inexactitudes et des carences dans la mise
    à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui
    fournissent ces informations.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Toutes les informations indiquées sur le site https://micropple.fr sont
    données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les
    renseignements figurant sur le site https://micropple.fr ne sont pas
    exhaustifs. Ils sont donnés sous réserve de modifications ayant été
    apportées depuis leur mise en ligne.
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    4. Limitations contractuelles sur les données techniques.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Le site utilise la technologie JavaScript. Le site Internet ne pourra être
    tenu responsable de dommages matériels liés à l’utilisation du site. De
    plus, l’utilisateur du site s’engage à accéder au site en utilisant un
    matériel récent, ne contenant pas de virus et avec un navigateur de dernière
    génération mis-à-jour Le site https://micropple.fr est hébergé chez un
    prestataire sur le territoire de l’Union Européenne conformément aux
    dispositions du Règlement Général sur la Protection des Données (RGPD : n°
    2016-679)
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    L’objectif est d’apporter une prestation qui assure le meilleur taux
    d’accessibilité. L’hébergeur assure la continuité de son service 24 Heures
    sur 24, tous les jours de l’année. Il se réserve néanmoins la possibilité
    d’interrompre le service d’hébergement pour les durées les plus courtes
    possibles notamment à des fins de maintenance, d’amélioration de ses
    infrastructures, de défaillance de ses infrastructures ou si les Prestations
    et Services génèrent un trafic réputé anormal.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr et l’hébergeur ne pourront être tenus responsables en
    cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou du
    matériel informatique et de téléphonie lié notamment à l’encombrement du
    réseau empêchant l’accès au serveur.
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    5. Propriété intellectuelle et contrefaçons.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr est propriétaire des droits de propriété intellectuelle
    et détient les droits d’usage sur tous les éléments accessibles sur le site
    internet, notamment les textes, images, graphismes, logos, vidéos, icônes et
    sons. Toute reproduction, représentation, modification, publication,
    adaptation de tout ou partie des éléments du site, quel que soit le moyen ou
    le procédé utilisé, est interdite, sauf autorisation écrite préalable de :
    https://micropple.fr.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Toute exploitation non autorisée du site ou de l’un quelconque des éléments
    qu’il contient sera considérée comme constitutive d’une contrefaçon et
    poursuivie conformément aux dispositions des articles L.335-2 et suivants du
    Code de Propriété Intellectuelle.
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    6. Limitations de responsabilité.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr agit en tant qu’éditeur du site. https://micropple.fr
    est responsable de la qualité et de la véracité du Contenu qu’il publie.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr ne pourra être tenu responsable des dommages directs et
    indirects causés au matériel de l’utilisateur, lors de l’accès au site
    internet https://micropple.fr, et résultant soit de l’utilisation d’un
    matériel ne répondant pas aux spécifications indiquées au point 4, soit de
    l’apparition d’un bug ou d’une incompatibilité.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr ne pourra également être tenu responsable des dommages
    indirects (tels par exemple qu’une perte de marché ou perte d’une chance)
    consécutifs à l’utilisation du site https://micropple.fr.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Des espaces interactifs (possibilité de poser des questions dans l’espace
    contact) sont à la disposition des utilisateurs. https://micropple.fr se
    réserve le droit de supprimer, sans mise en demeure préalable, tout contenu
    déposé dans cet espace qui contreviendrait à la législation applicable en
    France, en particulier aux dispositions relatives à la protection des
    données. Le cas échéant, https://micropple.fr se réserve également la
    possibilité de mettre en cause la responsabilité civile et/ou pénale de
    l’utilisateur, notamment en cas de message à caractère raciste, injurieux,
    diffamant, ou pornographique, quel que soit le support utilisé (texte,
    photographie …).
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    7. Gestion des données personnelles.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Le Client est informé des réglementations concernant la communication
    marketing, la loi du 21 Juin 2014 pour la confiance dans l’Économie
    Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du
    Règlement Général sur la Protection des Données (RGPD : n° 2016-679).
  </Typography>
  <Typography
    variant="h5"
    component="h3"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    7.1 Responsables de la collecte des données personnelles
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Pour les Données Personnelles collectées dans le cadre de la création du
    compte personnel de l’Utilisateur et de sa navigation sur le Site, le
    responsable du traitement des Données Personnelles est : Sébastien Torres.
    https://micropple.fr est représenté par Sébastien Torres, son représentant
    légal.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    En tant que responsable du traitement des données qu’il collecte,{" "}
    https://micropple.fr s’engage à respecter le cadre des dispositions
    légales en vigueur. Il lui appartient notamment d’établir les finalités
    de ses traitements de données, de fournir à ses prospects et clients, à
    partir de la collecte de leurs consentements, une information complète sur
    le traitement de leurs données personnelles et de maintenir un registre des
    traitements conforme à la réalité.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Chaque fois que https://micropple.fr traite des Données Personnelles,{" "}
    https://micropple.fr prend toutes les mesures raisonnables pour
    s’assurer de l’exactitude et de la pertinence des Données Personnelles au
    regard des finalités pour lesquelles https://micropple.fr les traite.
  </Typography>
  <Typography
    variant="h5"
    component="h3"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    7.2 Finalité des données collectées
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr est susceptible de traiter tout ou partie des
    données :
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - pour permettre la navigation sur le Site et la gestion et la traçabilité
    des prestations et services commandés par l’utilisateur : données de
    connexion et d’utilisation du Site, facturation, historique des commandes,
    etc.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - pour prévenir et lutter contre la fraude informatique (spamming, hacking)
    : matériel informatique utilisé pour la navigation, l’adresse IP, le mot de
    passe
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - pour améliorer la navigation sur le Site : données de connexion et
    d’utilisation
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - pour mener des enquêtes de satisfaction facultatives sur{" "}
    https://micropple.fr : adresse email
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - pour mener des campagnes de communication (sms, mail) : numéro de
    téléphone, adresse email
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr ne commercialise pas vos données personnelles qui
    sont donc uniquement utilisées par nécessité ou à des fins statistiques et
    d’analyses.
  </Typography>
  <Typography
    variant="h5"
    component="h3"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    7.3 Droit d’accès, de rectification et d’opposition
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Conformément à la législation européenne en vigueur, les Utilisateurs
    disposent des droits suivants :
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - Droit d'accès : ils peuvent exercer leur droit d'accès pour connaître
    les Données Personnelles les concernant. En cas de demande d’accès, ils
    devront prouver leur identité avec une pièce d’identité valide.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - Droit de rectification : si les Données Personnelles détenues par
    https://micropple.fr sont inexactes ou obsolètes, ils peuvent demander
    leur mise à jour.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    - Droit d’opposition : ils peuvent s’opposer au traitement de leurs
    Données Personnelles pour des raisons légitimes. Ils peuvent également
    s’opposer à ce que leurs Données Personnelles soient utilisées à des fins
    de prospection commerciale.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Pour exercer ces droits, les Utilisateurs doivent envoyer un email à :
    contact.micropple@gmail.com en indiquant leurs noms, prénoms et adresse
    email.
  </Typography>
  <Typography variant="h5" component="h3" sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}>
    7.4 Sécurité des données personnelles
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Afin d’assurer la sécurité des Données Personnelles, https://micropple.fr
    met en œuvre des mesures techniques et organisationnelles appropriées pour
    protéger les données contre la perte, le vol, l’accès non autorisé, la
    divulgation, la modification ou la destruction.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Ces mesures comprennent des contrôles d’accès, des sauvegardes régulières,
    des protocoles de cryptage et une formation du personnel aux bonnes
    pratiques en matière de protection des données.
  </Typography>
  <Typography variant="h5" component="h3" sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}>
    7.5 Durée de conservation des données personnelles
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Les Données Personnelles sont conservées pour une durée n’excédant pas
    celle nécessaire au regard des finalités pour lesquelles elles ont été
    collectées. Les données sont conservées pour une durée maximale de 3 ans
    à compter du dernier contact avec l’utilisateur ou de la dernière
    interaction.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Les données relatives à des factures peuvent être conservées pendant 10
    ans conformément aux obligations légales.
  </Typography>
  <Typography variant="h5" component="h3" sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}>
    7.6 Cookies
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Un "cookie" est un fichier de petite taille enregistré sur le terminal de
    l’Utilisateur (ordinateur, smartphone, tablette) lors de la visite d’un
    site internet. Les cookies ont pour but de signaler une précédente
    visite de l’Utilisateur sur le Site.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr utilise des cookies pour améliorer l’expérience
    utilisateur, réaliser des statistiques et analyser l’utilisation du Site.
    Les utilisateurs peuvent gérer les cookies via les paramètres de leur
    navigateur pour les accepter ou les refuser.
  </Typography>
  <Typography variant="h4" component="h2" sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}>
    8. Liens hypertextes
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Le Site peut contenir des liens hypertextes vers d’autres sites web. Les
    Utilisateurs doivent consulter les politiques de confidentialité de ces
    sites tiers, car https://micropple.fr ne saurait être tenu responsable de
    la gestion des données personnelles par ces derniers.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    La création de liens hypertextes vers le Site https://micropple.fr est
    autorisée sous réserve de l’accord préalable de https://micropple.fr.
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    9. Droit applicable et attribution de compétence.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Les présentes Conditions Générales sont régies par la loi française. En
    cas de litige concernant la validité, l’interprétation ou l’exécution des
    présentes, les tribunaux français seront seuls compétents.
  </Typography>
  <Typography
    variant="h4"
    component="h2"
    sx={{ fontWeight: "bold", color: "#1E3A8A", mt: 4, mb: 2 }}
  >
    10. Modifications des conditions d’utilisation.
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    https://micropple.fr se réserve le droit de modifier les présentes
    conditions d’utilisation à tout moment. Les utilisateurs seront informés
    de ces modifications par la publication des nouvelles conditions sur le Site.
    Les modifications entreront en vigueur dès leur publication.
  </Typography>
  <Typography variant="body1" sx={{ lineHeight: 1.7, mb: 2 }}>
    Il est conseillé aux utilisateurs de consulter régulièrement les conditions
    d’utilisation pour être informés de toute modification.
  </Typography>
</Box>
      

      {/* Section Politique de Confidentialité */}
      <Box id="confidentiality" sx={{ mb: 8 }}>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', color: '#1E3A8A', mb: 2 }}>
          Politique de Confidentialité
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
          Notre Politique de Confidentialité décrit comment nous collectons, utilisons, et protégeons vos informations personnelles lorsque vous utilisez nos services. Nous prenons au sérieux la protection de vos données et mettons en œuvre des mesures de sécurité pour empêcher l'accès non autorisé à vos informations.
        </Typography>
      </Box>

      {/* Section Termes & Conditions */}
      <Box id="terms" sx={{ mb: 8 }}>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', color: '#1E3A8A', mb: 2 }}>
          Termes & Conditions
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
          Les Termes & Conditions régissent l'utilisation de notre site et de nos services. En utilisant nos services, vous acceptez de vous conformer à ces conditions. Veuillez lire attentivement ces termes pour comprendre vos droits, obligations, et les limitations de responsabilité associées à l'utilisation de nos services.
        </Typography>
      </Box>
    </Container>
  );
};

export default LegalPage;
