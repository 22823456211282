import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import EnterprisePage from './pages/EnterprisePage';
import LegalPage from './pages/LegalPage';
import 'leaflet/dist/leaflet.css';
import ContactPage from './pages/ContactPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3d6dad',
    },
    secondary: {
      main: '#00bfff',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: '"ubuntu", Arial, sans-serif',
  },
});

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services-page" element={<ServicesPage />} />
          <Route path="/contact-page" element={<ContactPage />} />
          <Route path="/enterprise" element={<EnterprisePage />} />
          <Route path="legal" element={<LegalPage />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </Router>
  );
};

export default App;
