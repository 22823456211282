import React, { useState } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Box, Grid, styled, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import SvgIcon from '@mui/material/SvgIcon';

const CustomButton = styled(Button)(({ theme }) => ({
  fontFamily: 'inherit',
  fontSize: '20px',
  backgroundColor: '#1E3A8A',
  color: 'white',
  padding: '0.2em 1em',
  paddingLeft: '0.5em',
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  borderRadius: '16px',
  overflow: 'hidden',
  transition: 'all 0.2s',
  cursor: 'pointer',
  '& span': {
    display: 'block',
    marginLeft: '0.3em',
    transition: 'all 0.3s ease-in-out',
  },
  '& svg': {
    display: 'block',
    transformOrigin: 'center center',
    transition: 'transform 0.3s ease-in-out',
  },
  '&:hover .svg-wrapper': {
    animation: 'fly-1 0.6s ease-in-out infinite alternate',
  },
  '&:hover svg': {
    transform: 'translateX(1.2em) rotate(45deg) scale(1.1)',
  },
  '&:hover span': {
    transform: 'translateX(6em)',
  },
  '&:active': {
    transform: 'scale(0.95)',
  },
  '@keyframes fly-1': {
    from: {
      transform: 'translateY(0.1em)',
    },
    to: {
      transform: 'translateY(-0.1em)',
    },
  },
}));

function SendIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="black"
        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
      />
    </SvgIcon>
  );
}

const Contact = () => {
  const [form, setForm] = useState({
    nom: '',
    prenom: '',
    email: '',
    telephone: '',
    service: '',
    subService: '',
    description: '',
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { nom, prenom, email, service, description } = form;
    if (!nom || !prenom || !email || !service || !description) {
      alert('Veuillez remplir tous les champs obligatoires.');
      return;
    }

    axios.post('https://micropple.fr/app2/send', form) // Remplacez l'URL par votre URL de serveur
      .then(response => {
        alert('Email envoyé avec succès!');
        setForm({
          nom: '',
          prenom: '',
          email: '',
          telephone: '',
          service: '',
          subService: '',
          description: '',
        });
      })
      .catch(error => {
        console.error('Erreur lors de l\'envoi de l\'email:', error);
      });
  };

  const renderSubServiceSelect = () => {
    let subServiceOptions = [];

    if (form.service === 'depot-produit-entreprise') {
      subServiceOptions = [
        { value: 'reparation-ordinateur', label: 'Réparation ordinateur' },
        { value: 'reparation-telephone', label: 'Réparation téléphone' },
        { value: 'montage-pc-gamer', label: 'Montage PC Gamer' },
      ];
    } else if (form.service === 'retrait-produit-domicile') {
      subServiceOptions = [
        { value: 'reparation-ordinateur-domicile', label: 'Réparation ordinateur (frais supplémentaire)' },
        { value: 'reparation-telephone-domicile', label: 'Réparation téléphone (frais supplémentaire)' },
        { value: 'montage-pc-gamer-domicile', label: 'Montage PC Gamer (frais supplémentaire)' },
      ];
    }

    if (subServiceOptions.length > 0) {
      return (
        <FormControl fullWidth required sx={{ mb: 2, '& .MuiInputBase-root': { color: '#E0E0E0' }, '& .MuiFormLabel-root': { color: '#E0E0E0' } }}>
          <InputLabel id="sub-service-label" sx={{ color: '#E0E0E0' }}>Type de service</InputLabel>
          <Select
            labelId="sub-service-label"
            id="subService"
            name="subService"
            value={form.subService}
            onChange={handleChange}
            label="Type de service"
          >
            {subServiceOptions.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  return (
    <Box sx={{ background: '#3B82F6', color: '#E0E0E0'}}>
      <Container id="contact" sx={{ padding: '40px 0', borderRadius: '8px' }}>
        <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ fontFamily: 'Bebas Neue, Arial, sans-serif' }}>
          Fiche client
        </Typography>
        <Typography variant="body1" paragraph align="center">
          Pour plus d’informations et pour prendre rendez-vous, vous pouvez dès maintenant, remplir le formulaire ci-dessous pour pouvoir rédiger votre fiche client.
          <br/>
          Un spécialiste vous recontactera dans les plus brefs délais.
        </Typography>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" component="h3" gutterBottom align="center">
            Formulaire de Contact
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nom"
                  name="nom"
                  variant="outlined"
                  value={form.nom}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2, '& .MuiInputBase-root': { color: '#E0E0E0' }, '& .MuiFormLabel-root': { color: '#E0E0E0' } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Prénom"
                  name="prenom"
                  variant="outlined"
                  value={form.prenom}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2, '& .MuiInputBase-root': { color: '#E0E0E0' }, '& .MuiFormLabel-root': { color: '#E0E0E0' } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="E-mail"
                  name="email"
                  variant="outlined"
                  value={form.email}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2, '& .MuiInputBase-root': { color: '#E0E0E0' }, '& .MuiFormLabel-root': { color: '#E0E0E0' } }}
                  InputProps={{
                    startAdornment: (
                      <EmailIcon sx={{ marginRight: '8px', color: 'action.active' }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Numéro de téléphone"
                  name="telephone"
                  variant="outlined"
                  value={form.telephone}
                  onChange={handleChange}
                  required
                  sx={{ mb: 2, '& .MuiInputBase-root': { color: '#E0E0E0' }, '& .MuiFormLabel-root': { color: '#E0E0E0' } }}
                  InputProps={{
                    startAdornment: (
                      <PhoneIcon sx={{ marginRight: '8px', color: 'action.active' }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required sx={{ mb: 2, '& .MuiInputBase-root': { color: '#E0E0E0' }, '& .MuiFormLabel-root': { color: '#E0E0E0' } }}>
                  <InputLabel id="service-label" sx={{ color: '#E0E0E0' }}>Service</InputLabel>
                  <Select
                    labelId="service-label"
                    id="service"
                    name="service"
                    value={form.service}
                    onChange={handleChange}
                    label="Service"
                  >
                    <MenuItem value="depot-produit-entreprise">Dépot du produit à l'entreprise</MenuItem>
                    <MenuItem value="retrait-produit-domicile">Un technicien pour se déplacer chez vous</MenuItem>
                    <MenuItem value="creation-sites-web">Création de sites</MenuItem>
                    <MenuItem value="conseils-informatiques">Conseils informatiques</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {renderSubServiceSelect()}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={form.description}
                  onChange={handleChange}
                  required
                  sx={{ '& .MuiInputBase-root': { color: '#E0E0E0' }, '& .MuiFormLabel-root': { color: '#E0E0E0' } }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CustomButton type="submit">
                <div className="svg-wrapper-1">
                  <div className="svg-wrapper">
                    <SendIcon />
                  </div>
                </div>
                <span>Envoyer</span>
              </CustomButton>
            </Box>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
