import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardMedia } from '@mui/material';
import Yass from '../images/team/yassine.jpg';
import Youns from '../images/team/youns.jpg';
import Adil from '../images/team/adil.jpg';
import Nael from '../images/team/nael.jpg';
const teamMembers = [
  {
    name: 'Yassine',
    role: 'Directeur Technique & Développeur',
    bio: 'Yassine supervise les opérations techniques et assure le bon déroulement des projets de développement. Il est le moteur technique de l\'équipe et veille à l\'adoption des meilleures pratiques en développement logiciel.',
    image: Yass,
  },
  {
    name: 'Younes',
    role: 'Développeur Full Stack',
    bio: 'Younes est un développeur polyvalent, maîtrisant à la fois le front-end et le back-end. Il est passionné par la création de solutions complètes et efficaces, de la base de données à l\'interface utilisateur.',
    image: Youns,
  },
  {
    name: 'Adil',
    role: 'Développeur Front-end',
    bio: 'Adil est spécialisé dans le développement d\'interfaces utilisateur réactives et modernes. Il est toujours à la recherche des dernières tendances en matière de design web pour améliorer l\'expérience utilisateur.',
    image: Adil,
  },
  {
    name: 'Nael',
    role: 'Développeur Back-end',
    bio: 'Nael se concentre sur le développement des services backend robustes et scalables. Son expertise en gestion de bases de données et en sécurité fait de lui un atout précieux pour l\'équipe.',
    image: Nael,
  },
];

const EnterprisePage = () => {
  return (
    <Box>
    <br />
    <br />
    <br />
    <Container sx={{ py: 8 }}>
      {/* Section Notre entreprise */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
          Notre entreprise
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
          MICROPPLE est une entreprise dédiée à fournir des solutions technologiques innovantes et des services de réparation de haute qualité. Depuis notre fondation en 2012, nous avons aidé des centaines de clients à résoudre leurs problèmes technologiques, en nous concentrant sur la satisfaction client et l'excellence du service.
        </Typography>
      </Box>

      {/* Section À propos de Nous */}
      <Box sx={{ mb: 8 }}>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
          À propos de Nous
        </Typography>
        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
          Chez MICROPPLE, nous croyons que la technologie doit être accessible à tous. Notre mission est de rendre la technologie simple, intuitive et accessible, que ce soit pour les particuliers ou les entreprises. Nous offrons une gamme complète de services, allant de la réparation d'équipements à la création de sites web, en passant par le conseil en informatique.
        </Typography>
      </Box>

      {/* Section Notre équipe Développement */}
      <Box>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
          Notre équipe Développement
        </Typography>
        <Grid container spacing={4}>
          {teamMembers.map((member, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Card sx={{ maxWidth: 345, backgroundColor: '#1E3A8A', color: '#E0E0E0' }}>
                <CardMedia
                  component="img"
                  height="240"
                  image={member.image}
                  alt={member.name}
                />
                <CardContent>
                  <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                    {member.name}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {member.role}
                  </Typography>
                  <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    {member.bio}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
    </Box>
  );
};

export default EnterprisePage;
